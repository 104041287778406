<template>

  <TablePage ref="TablePage" main-url="/sppr-requests" @tableItemsClick="tableItemsClick">

    <template #toolBarRightSide>
      <Button class="p-button p-button-outlined mr-3" label="Запросить отчет"
              @click="requestReportShowCard"/>
      <Button class="p-button p-button-outlined" label="Запросить список доступных отчетов"
              @click="requestActualReferences"/>
    </template>

    <template #tableColumns="TableProps">

      <Column :sortable="true" field="time_created">
        <template #header>
          <SearchDate v-model="TableProps.filters['time_created']" label="Контейнер отправлен"/>
        </template>
        <template #body="slotProps">
          {{ unixTimeStampToDateTime(slotProps.data?.['time_created']) }}
        </template>
      </Column>

      <Column :sortable="true" style="width:15%">
        <template #header>
          <SearchSelect v-model="TableProps.filters['delivery_status']"
                        :options="deliveryStatuses"
                        label="Статус"
                        option-label="key"
                        option-value="value"/>
        </template>
        <template #body="slotProps">
                        <span>{{
                            getObjectFromArrayByPropertyValue(deliveryStatuses, 'value', slotProps.data.delivery_status).key || 'Статус неизвестен'
                          }}</span>
        </template>
      </Column>
      <Column :sortable="true" field="request_guid" filter-field="request_guid">
        <template #header>
          <SearchInput v-model="TableProps.filters['request_guid']" label="Идентификатор запроса"/>
        </template>
      </Column>
      <Column :sortable="true" field="container_guid" filter-field="container_guid">
        <template #header>
          <SearchInput v-model="TableProps.filters['container_guid']" label="Идентификатор контейнера"/>
        </template>
      </Column>
      <Column :sortable="true" field="type" filter-field="type">
        <template #header>
          <SearchInput v-model="TableProps.filters['type']" label="Тип запроса"/>
        </template>
      </Column>

    </template>

  </TablePage>

  <RequestReportCard ref="RequestReportCard" @okBtnClicked="onRequestCardOkBtnClicked"/>
  <RequestCard ref="RequestCard" :deliveryStatuses="deliveryStatuses" page-url="sppr-requests"/>

</template>

<script>

import {DataFilters, Helper, SearchDate, SearchInput, SearchSelect, TablePage} from "@xnpmpackages/xcomponents";
import RequestReportCard from "./sppr_requests/RequestReportCard";
import RequestCard from "./sppr_requests/RequestCard";


export default {
  name: "SpprRequests",
  data() {
    return {

      nowSelectedReport: null,

      reportAddViewLoading: false,

      loadingElement: null,

      deliveryStatuses: [
        {key: 'Отправлен', value: 0},
        {key: 'Принят организатором', value: 1},
        {key: 'Не принят организатором', value: 2},
        {key: 'Предоставлен полный ответ', value: 3},
        {key: 'Предоставлен частичный ответ', value: 4},
        {key: 'В предоставлении данных отказано', value: 5},
        {key: 'Все', value: null}]

    }
  },
  mixins: [Helper, DataFilters],
  methods: {

    async requestReportShowCard() {

      this.$refs.RequestReportCard.show();

    },

    async tableItemsClick($event) {

      await this.$refs.RequestCard.show(this.copyObjectByJSON($event.data), this.$refs.TablePage.tableItems);

    },

    async requestActualReferences() {

      this.$confirm.require({
        message: 'В СППР будет отправлен контейнер с запросом на получение актуального справочника. Продолжить?',
        header: 'Подтверждение',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Да',
        rejectLabel: 'Нет',
        accept: async () => {

          try {

            this.$xapi.showBlockModal('Формирование контейнера...');

            await this.$xapi.post(
              '/sppr-requests',
              {
                action: 'requestActualReferences',
              },
            );

            this.$xapi.xnotify('В СППР отправлен запрос');
            this.$refs.TablePage.getTableRows(true);

          } finally {
            this.$xapi.hideBlockModal();
          }

        },
        reject: async () => {
          this.$xapi.hideBlockModal();
        },

      });

    },

    onRequestCardOkBtnClicked(request) {

      this.$refs.TablePage.getTableRows(true);
      this.$refs.RequestCard.show(request);

    },


  },

  components: {
    RequestCard,
    RequestReportCard,
    SearchInput,
    SearchSelect,
    SearchDate,
    TablePage
  }
}
</script>

<style scoped>

</style>