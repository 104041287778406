<template>

  <div>

    <XModal ref="XModal" :show-overlay="true" @hide="OnHide">

      <template #header>

        <div class="flex align-items-center">
          <span>Просмотр карточки запроса</span>
          <ProgressSpinner v-if="[0, 1].includes(itemData.delivery_status)" class="ml-3"
                           strokeWidth="8" style="width: 20px; height: 20px"/>
        </div>

      </template>

      <template #default>

        <div :style="{minWidth: '30vw', maxWidth: '80vw'}">

          <TabView v-model:activeIndex="tabActiveIndex">

            <TabPanel v-if="responseContent" header="Отчет">

              <iframe :srcdoc="this.responseContent" style="min-width: 80vw; min-height: 70vh;"/>

            </TabPanel>

            <TabPanel header="Запрос">

              <table class="p-datatable-table" style="width: 100%;">
                <tbody>

                <tr>
                  <td>Идентификатор запроса</td>
                  <td>{{ itemData.request_guid }}</td>
                </tr>
                <tr>
                  <td>Идентификатор контейнера</td>
                  <td>{{ itemData.container_guid }}</td>
                </tr>
                <tr>
                  <td>Идентификатор сообщения</td>
                  <td>{{ itemData.message_guid }}</td>
                </tr>
                <tr>
                  <td>Идентификатор квитанции о получении запроса</td>
                  <td>{{ itemData.acknowledgement_guid }}</td>
                </tr>
                <tr>
                  <td>Время формирования запроса</td>
                  <td>{{ unixTimeStampToDateTime(itemData.time_created) }}</td>
                </tr>
                <tr>
                  <td>Время доставки запроса организатору</td>
                  <td>
                    <span v-if="!itemData.delivered_time">Не доставлен</span>
                    <span v-if="itemData.delivered_time">{{
                        unixTimeStampToDateTime(itemData.delivered_time)
                      }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Тип запроса</td>
                  <td>{{ itemData.type }}</td>
                </tr>
                <tr>
                  <td>Статус</td>
                  <td>
                    {{
                      getObjectFromArrayByPropertyValue(deliveryStatuses, 'value', itemData.delivery_status).key
                    }}
                  </td>
                </tr>
                <tr v-if="itemData.delivery_fail_reason">
                  <td>Причина недоставки</td>
                  <td>{{ itemData.delivery_fail_reason }}</td>
                </tr>
                <tr v-if="itemData.response_time">
                  <td>Время ответа от организатора</td>
                  <td>{{ itemData.response_time }}</td>
                </tr>
                <tr v-if="itemData?.additionalData?.requests">
                  <td>Запрошенные отчеты</td>
                  <td>
                    <ul>
                      <li v-for="item in itemData.additionalData.requests" :key="item.queryId">
                        <div>{{ item.reportName }}</div>
                        <div>
                                            <span
                                              v-if="itemData?.additionalData?.response?.denyReports?.[item.queryId] || itemData?.additionalData?.response?.successReports?.[item.queryId]">
                                                <span>Ответ: &nbsp;</span>
                                                <span
                                                  v-if="itemData?.additionalData?.response?.successReports?.[item.queryId]"
                                                  style="color: var(--green-500)">Предоставлен отчет</span>
                                                <span
                                                  v-if="itemData?.additionalData?.response?.denyReports?.[item.queryId]"
                                                  style="color: var(--red-500)">{{
                                                    itemData?.additionalData?.response?.denyReports?.[item.queryId].rejectionReason
                                                  }}</span>
                                            </span>
                          <span v-else
                                style="color: var(--yellow-500)">Организатор еще не ответил</span>

                        </div>
                      </li>
                    </ul>
                  </td>
                </tr>


                </tbody>
              </table>

            </TabPanel>


          </TabView>

        </div>


      </template>

      <template #footerLeftOfHide>
        <Button :disabled="!itemData?.response_container_path" class="p-button-success p-button-outlined"
                label="Выгрузить контейнер-ответ"
                @click="downloadResponse"/>
        <Button class="p-button-success p-button-outlined" label="Выгрузить xml файл запроса"
                @click="downloadRequest"/>
      </template>

    </XModal>

  </div>

</template>

<script>
import {DataFilters, Helper, XModal} from "@xnpmpackages/xcomponents";
import Xapi from "@xnpmpackages/xcomponents/dist/utils/xapi";
import ProgressSpinner from "primevue/progressspinner";

export default {
  name: "RequestCard",
  mixins: [DataFilters, Helper],
  props: {
    deliveryStatuses: null,
    pageUrl: null,
  },
  components: {XModal, ProgressSpinner},
  data() {
    return {
      itemData: null,
      responseContent: null,
      updateRequestStatusTimer: null,
      tabActiveIndex: 0,
    }
  },
  methods: {
    show($itemData) {

      this.itemData = $itemData;
      this.$refs.XModal.show();
      this.updateRequestStatus();

    },
    downloadRequest() {
      this.downloadStringAsFile(this.itemData.request_xml_content, this.itemData.request_guid + '.xml');
    },
    async downloadResponse() {

      try {

        this.$xapi.showBlockModal('Выгрузка файла...');
        await this.downloadFileFromUrl('/' + this.pageUrl + '/downloadResponse/' + this.itemData.request_guid);

      } catch (e) {

        this.$xapi.xerror('Не удалось выгрузить файл');

      } finally {
        this.$xapi.hideBlockModal();
      }


    },

    async updateRequestStatus() {

      let response = await Xapi.post(
        '/sppr-requests',
        {
          action: 'getRequestStatus',
          request_guid: this.itemData.request_guid
        },
      );

      this.itemData = response?.data?.request;

      if (response?.data?.report_content) {
        this.responseContent = response?.data?.report_content;
        this.tabActiveIndex = 0;
      }

      if (!([0, 1].includes(this.itemData?.delivery_status))) {
        this.stopUpdateRequestStatus();
      } else {
        this.updateRequestStatusTimer = setTimeout(this.updateRequestStatus, 1000);
      }

    },


    stopUpdateRequestStatus() {

      if (this.updateRequestStatusTimer) {
        clearTimeout(this.updateRequestStatusTimer);
        this.updateRequestStatusTimer = null;
      }

    },

    OnHide() {

      this.responseContent = null;
      this.stopUpdateRequestStatus();

    },


  },
}
</script>

<style scoped>

.p-datatable-table {
  border-collapse: collapse;
}

.p-datatable-table tbody > tr > td, .p-datatable-table thead > tr > td {
  padding: 5px 20px;
}

.p-datatable-table > tbody > tr:nth-child(even) {
  /*background-color: #0a3622;*/
  background-color: var(--surface-ground);
}

</style>